import { REVIEW_QUESTION_EVALUATORS, REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IReviewQuestion, IReviewQuestionDefaultData } from '@learned/types';

export const isRequiredRatedQuestion = (question: IReviewQuestion, themeId: string) => {
  return (
    question.theme === themeId &&
    question.type === REVIEW_QUESTION_TYPES.RATING &&
    (question.settings as IReviewQuestionDefaultData).isAnswerObligated &&
    (question.settings as IReviewQuestionDefaultData)?.isManualScale === false &&
    (question.settings as IReviewQuestionDefaultData)?.evaluators.includes(
      REVIEW_QUESTION_EVALUATORS.COACH,
    )
  );
};
