import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Section = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.div<{ color: string; isOpen?: boolean; isClickable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 10px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  .left {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    font-size: 14px;
    color: ${({ color }) => color || 'unset'};
  }

  .right {
    display: flex;
    align-items: center;
    svg {
      rotate: ${({ isOpen }) => (isOpen ? '-90deg' : '0')};
    }
    cursor: pointer;
  }
`;

export const CareerPlanCardWrapper = styled.div`
  min-height: 52px;
  background-color: ${COLORS.BG_SWITCH};
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${COLORS.HOVER};
  }

  .name {
    width: 100%;
    font-size: 14px;
    color: ${COLORS.TEXT_MAIN};
    word-break: break-all;
  }
  .timeFrame {
    font-size: 12px;
    color: ${COLORS.LABEL_GRAY};
  }
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PlaceholderText = styled.div`
  max-width: 300px;
  font-size: 14px;
  text-align: center;
  color: ${COLORS.LABEL_GRAY};
  margin-top: 30px;
`;

export const PlaceholderSection = styled.div`
  font-size: 14px;
  color: ${COLORS.LABEL_GRAY};
  padding: 16px;
`;
