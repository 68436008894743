import { useEffect, useState } from 'react';

import {
  REVIEW_AVERAGE_SCORE_COLUMNS,
  REVIEW_AVERAGE_SCORE_ROW_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_RATING_TYPE,
  TASK_STATUS,
  TASK_TYPE,
} from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { createColumns } from '../components/RatingsGrid/columns';
import { IChildTransformed, IColumn, IRow, IRowTransformed } from '../types';
import { isRequiredRatedQuestion, transformRowsToItems } from '../utils';

import type {
  IUserReview,
  ITask,
  IUserReviewQuestion,
  IReviewTheme,
  IReviewQuestionDefaultData,
  IReviewTemplate,
  IReviewQuestion,
  IReviewRating,
  IMultiLangString,
} from '@learned/types';

type IPopulatedUserReviewType = IUserReview & {
  tasks: ITask[];
  employeeTasksInOtherUserReviews: ITask[];
  userReviewQuestions: IUserReviewQuestion[];
  reviewTemplate?: IReviewTemplate;
};

interface IUseReviewAverageScoreProps {
  userReview?: IPopulatedUserReviewType;
}

const columnsList = (i18n: I18n, userReview?: IPopulatedUserReviewType) => {
  if (!userReview) {
    return [];
  }

  const { tasks = [], userReviewQuestions = [], peers = [] } = userReview;

  const countCompletedTasks = (taskType: string) =>
    tasks.filter((task) => task.type === taskType && task.status === TASK_STATUS.COMPLETED).length;

  const isEvaluatorRequired = (evaluator: REVIEW_QUESTION_EVALUATORS) =>
    (userReviewQuestions as IUserReviewQuestion[]).some(
      (question) =>
        isRequiredRatedQuestion(question) &&
        (question.settings as IReviewQuestionDefaultData).evaluators.includes(evaluator),
    );

  const peerRatingCount = countCompletedTasks(TASK_TYPE.REVIEW_PEER_EVALUATE);
  const coachRatingCount = countCompletedTasks(TASK_TYPE.REVIEW_COACH_EVALUATE);
  const isPeerReviewRequired =
    isEvaluatorRequired(REVIEW_QUESTION_EVALUATORS.PEER) && peers.length > 0;
  const isSelfReviewRequired = isEvaluatorRequired(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);

  const createColumn = (id: string, name: string, count?: number) => ({
    id,
    name: i18n._(t`${name}`),
    count,
    accessor: id,
    isFixed: false,
    centerAlign: true,
  });

  return [
    createColumn(REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT, 'Weight'),
    isPeerReviewRequired
      ? createColumn(REVIEW_AVERAGE_SCORE_COLUMNS.PEER, 'Peer', peerRatingCount)
      : undefined,
    isSelfReviewRequired ? createColumn(REVIEW_AVERAGE_SCORE_COLUMNS.SELF, 'Self') : undefined,
    createColumn(REVIEW_AVERAGE_SCORE_COLUMNS.COACH, 'Coach', coachRatingCount),
  ].filter(Boolean);
};

export const useReviewAverageScore = ({ userReview }: IUseReviewAverageScoreProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [items, setItems] = useState<(IRowTransformed | IChildTransformed)[]>([]);
  const [weightedAverage, setWeightedAverage] = useState<Record<string, number>>({});
  const [columns, setColumns] = useState([]);
  const [totalOptions, setTotalOptions] = useState<{ label: IMultiLangString }[]>();

  const itemsFiltered = items.filter(
    (item) =>
      item.type === REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME || (item as IChildTransformed).isVisible,
  );

  const groupUserReviewQuestions = () => {
    const reviewQuestionsByTheme: Record<string, any> = {};
    userReview?.userReviewQuestions.forEach((question) => {
      const reviewQuestion = question as unknown as IUserReviewQuestion;
      const reviewTheme = reviewQuestion.theme as unknown as IReviewTheme;
      const theme = reviewQuestionsByTheme[reviewTheme.id];

      if (!isRequiredRatedQuestion(reviewQuestion)) {
        return;
      }

      if (theme) {
        reviewQuestionsByTheme[theme.id] = {
          ...theme,
          questions: [...theme.questions, question],
        };
      } else {
        reviewQuestionsByTheme[reviewTheme.id] = {
          ...reviewTheme,
          weight: userReview?.reviewTemplate?.themeWeights?.[reviewTheme.id],
          questions: [question],
        };
      }
    });
    return Object.values(reviewQuestionsByTheme);
  };

  const getDisabledAverages = (
    averageScores: IRow[],
  ): Record<REVIEW_AVERAGE_SCORE_COLUMNS.SELF | REVIEW_AVERAGE_SCORE_COLUMNS.PEER, boolean> => {
    const disabledAverages: Record<
      REVIEW_AVERAGE_SCORE_COLUMNS.SELF | REVIEW_AVERAGE_SCORE_COLUMNS.PEER,
      boolean
    > = {
      [REVIEW_AVERAGE_SCORE_COLUMNS.SELF]: false,
      [REVIEW_AVERAGE_SCORE_COLUMNS.PEER]: false,
    };

    for (const columnId of [REVIEW_AVERAGE_SCORE_COLUMNS.SELF, REVIEW_AVERAGE_SCORE_COLUMNS.PEER]) {
      const hasInvalidValue = averageScores.some((theme) => {
        const cell = theme.cells.find((cell) => cell.columnId === columnId);
        return cell && Number(cell.value) === 0;
      });

      if (hasInvalidValue) {
        disabledAverages[
          columnId as REVIEW_AVERAGE_SCORE_COLUMNS.SELF | REVIEW_AVERAGE_SCORE_COLUMNS.PEER
        ] = true;
      }
    }

    return disabledAverages;
  };

  const calculateAverageScore = (ratings: IReviewRating[], type: string) => {
    const filteredRatings = ratings?.filter((rating) => rating?.type === type);
    return filteredRatings?.length
      ? filteredRatings.reduce((total, rating) => total + Number(rating.answer), 0) /
          filteredRatings.length
      : 0;
  };

  const getThemeAverageScore = (
    theme: IReviewTheme & { questions: (IReviewQuestion & { reviewRatings: IReviewRating[] })[] },
  ) => {
    const totalOptions = (theme.questions[0]?.settings as IReviewQuestionDefaultData)?.options
      ?.length;

    const peerRating = calculateAverageScore(
      theme.questions.flatMap(
        (question: IReviewQuestion & { reviewRatings: IReviewRating[] }) => question.reviewRatings,
      ),
      REVIEW_RATING_TYPE.PEER,
    );
    const selfRating = calculateAverageScore(
      theme.questions.flatMap(
        (question: IReviewQuestion & { reviewRatings: IReviewRating[] }) => question.reviewRatings,
      ),
      REVIEW_RATING_TYPE.SELF,
    );
    const coachRating = calculateAverageScore(
      theme.questions.flatMap(
        (question: IReviewQuestion & { reviewRatings: IReviewRating[] }) => question.reviewRatings,
      ),
      REVIEW_RATING_TYPE.COACH,
    );

    return [
      {
        columnId: REVIEW_AVERAGE_SCORE_COLUMNS.PEER,
        value: Number(peerRating.toFixed(1)),
        all: totalOptions,
      },
      {
        columnId: REVIEW_AVERAGE_SCORE_COLUMNS.SELF,
        value: Number(selfRating.toFixed(1)),
        all: totalOptions,
      },
      {
        columnId: REVIEW_AVERAGE_SCORE_COLUMNS.COACH,
        value: Number(coachRating.toFixed(1)),
        all: totalOptions,
      },
    ];
  };

  const mapQuestionRatings = (
    theme: IReviewTheme & { questions: (IReviewQuestion & { reviewRatings: IReviewRating[] })[] },
  ) => {
    const totalOptions = (theme.questions?.[0]?.settings as unknown as IReviewQuestionDefaultData)
      ?.options?.length;
    return theme.questions.map((question: IReviewQuestion & { reviewRatings: IReviewRating[] }) => {
      return {
        id: question.id,
        type: REVIEW_AVERAGE_SCORE_ROW_TYPE.QUESTION,
        name: getMultiLangString(question.name),
        themeId: theme.id,
        cells: [
          {
            columnId: REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT,
            value: '',
          },
          {
            columnId: REVIEW_AVERAGE_SCORE_COLUMNS.PEER,
            value: calculateAverageScore(question.reviewRatings, REVIEW_RATING_TYPE.PEER),
            all: totalOptions,
          },
          {
            columnId: REVIEW_AVERAGE_SCORE_COLUMNS.SELF,
            value: calculateAverageScore(question.reviewRatings, REVIEW_RATING_TYPE.SELF),
            all: totalOptions,
          },
          {
            columnId: REVIEW_AVERAGE_SCORE_COLUMNS.COACH,
            value: calculateAverageScore(question.reviewRatings, REVIEW_RATING_TYPE.COACH),
            all: totalOptions,
          },
        ],
      };
    });
  };

  const mapThemeQuestionsToTableData = () => {
    const groupedThemeQuestions = groupUserReviewQuestions();
    if (!groupedThemeQuestions?.length) {
      return [];
    }

    const totalOptions =
      (groupedThemeQuestions[0].questions?.[0]?.settings as unknown as IReviewQuestionDefaultData)
        ?.options || [];

    setTotalOptions(totalOptions);

    const calculateWeightedAverage = (
      averageScores: IRow[],
      columnId: REVIEW_AVERAGE_SCORE_COLUMNS,
    ) => {
      return Number(
        averageScores
          .reduce((total, theme) => {
            const weight = Number(theme.cells?.[0]?.value) || 0;
            const value =
              Number(
                theme.cells.find((cell: { columnId: string }) => cell.columnId === columnId)?.value,
              ) || 0;
            return total + (value * weight) / 100;
          }, 0)
          .toFixed(1),
      );
    };

    const generateThemeCells = (
      theme: IReviewTheme & {
        weight: number;
        questions: (IReviewQuestion & {
          reviewRatings: IReviewRating[];
        })[];
      },
    ) => [
      {
        columnId: REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT,
        value: theme.weight || 0,
      },
      ...getThemeAverageScore(theme),
    ];

    const averageRatingScore = groupedThemeQuestions.map((theme) => ({
      id: theme.id,
      type: REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME,
      name: getMultiLangString((theme as IReviewTheme).name),
      cells: generateThemeCells(theme),
      children: mapQuestionRatings(theme),
    }));

    const disabledAverages = getDisabledAverages(averageRatingScore as unknown as IRow[]);

    const weightedAverages = {
      [REVIEW_AVERAGE_SCORE_COLUMNS.PEER]: !disabledAverages[REVIEW_AVERAGE_SCORE_COLUMNS.PEER]
        ? calculateWeightedAverage(
            averageRatingScore as unknown as IRow[],
            REVIEW_AVERAGE_SCORE_COLUMNS.PEER,
          )
        : 0,
      [REVIEW_AVERAGE_SCORE_COLUMNS.COACH]: calculateWeightedAverage(
        averageRatingScore as unknown as IRow[],
        REVIEW_AVERAGE_SCORE_COLUMNS.COACH,
      ),
      [REVIEW_AVERAGE_SCORE_COLUMNS.SELF]: !disabledAverages[REVIEW_AVERAGE_SCORE_COLUMNS.SELF]
        ? calculateWeightedAverage(
            averageRatingScore as unknown as IRow[],
            REVIEW_AVERAGE_SCORE_COLUMNS.SELF,
          )
        : 0,
    };

    setWeightedAverage(weightedAverages);

    const weightedAverageRow = {
      id: '1',
      type: REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME,
      name: i18n._(t`Weighted Average`),
      cells: [
        { columnId: REVIEW_AVERAGE_SCORE_COLUMNS.WEIGHT, value: '100' },
        {
          columnId: REVIEW_AVERAGE_SCORE_COLUMNS.PEER,
          value: weightedAverages[REVIEW_AVERAGE_SCORE_COLUMNS.PEER],
          all: totalOptions.length,
        },
        {
          columnId: REVIEW_AVERAGE_SCORE_COLUMNS.SELF,
          value: weightedAverages[REVIEW_AVERAGE_SCORE_COLUMNS.SELF],
          all: totalOptions.length,
        },
        {
          columnId: REVIEW_AVERAGE_SCORE_COLUMNS.COACH,
          value: weightedAverages[REVIEW_AVERAGE_SCORE_COLUMNS.COACH],
          all: totalOptions.length,
        },
      ],
    };

    return [...averageRatingScore, weightedAverageRow];
  };

  useEffect(() => {
    const averageRatingScore = mapThemeQuestionsToTableData();
    setItems(transformRowsToItems(averageRatingScore as unknown as IRow[]));

    const cols = createColumns(columnsList(i18n, userReview) as IColumn[]);
    // @ts-ignore
    setColumns(cols);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReview]);

  const toggleThemeVisibility = (
    items: (IRowTransformed | IChildTransformed)[],
    clickedItem: IRowTransformed | IChildTransformed,
  ) =>
    items.map((item) => {
      if (
        item.type === REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME &&
        item.temporalUniqueId === clickedItem.temporalUniqueId
      ) {
        return { ...item, isCollapsed: !(item as unknown as IRowTransformed).isCollapsed };
      }
      if (
        item.type !== REVIEW_AVERAGE_SCORE_ROW_TYPE.THEME &&
        (item as IChildTransformed).parent === clickedItem.temporalUniqueId
      ) {
        return { ...item, isVisible: !(item as IChildTransformed).isVisible };
      }
      return item;
    });

  const onToggleTheme = (clickedItem: IRowTransformed | IChildTransformed) => {
    const rowsUpdated = toggleThemeVisibility(items, clickedItem);
    setItems(rowsUpdated);
  };

  return {
    items: itemsFiltered,
    columns,
    totalOptions,
    onToggleTheme,
    weightedAverage,
  };
};
