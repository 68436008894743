import { REVIEW_QUESTION_EVALUATORS, REVIEW_STATUS } from '@learned/constants';

import { ICONS } from '~/components/Icon';

import type {
  ICareerPlan,
  IJobProfile,
  ILanguageValue,
  IReview,
  IReviewInvitationTemplate,
  IReviewQuestion,
  IReviewTemplate,
  ISkill,
  ISkillCategory,
  ITeam,
  IUser,
  IUserReview,
} from '@learned/types';

export type IEmployee = IUser & {
  jobProfiles: IJobProfile[];
  teams: ITeam[];
  guests: string[];
  coaches: string[];
  // all available job profiles for the user
  availableJobProfiles: IJobProfile[];
  // all available coaches for the user
  availableCoaches: string[];
  // all available careerPlans for the user
  availableCareerPlans: ICareerPlan[];
  careerPlans?: ICareerPlan[];
  userReview?: IUserReview['id'];
  careerPlanIds?: ICareerPlan['id'][];
};

export interface IReviewCycleForm {
  name: ILanguageValue[];
  description?: ILanguageValue[] | null;
  reviewTemplate?: IReviewTemplate['id'];
  reviewInvitationTemplate?: IReviewInvitationTemplate['id'];
  reviewTemplateData?: IReviewTemplate;
  reviewTemplateName: ILanguageValue[];
  reviewInvitationTemplateName?: ILanguageValue[] | null;
  employees: Array<IEmployee>;
  status: REVIEW_STATUS;
  skills: Array<ISkill>;
  skillCategories: Record<string, ISkillCategory>;
  evaluators: Array<{
    value: REVIEW_QUESTION_EVALUATORS;
    icon: ICONS;
    title: string;
  }>;
  reviewQuestionTypes: Array<IReviewQuestion['type']>;
  isExpandMode?: boolean;
  settings: {
    isCoachesAskedToScheduleReview: boolean;
    isAutoArchive: boolean;
    isAutoTimeline: boolean;
    isCalibrate: boolean;
    isDigitalSign: boolean;
    startDate: Date | null;
    endDate: Date | null;
    isShowOverallRating: boolean;
  };
  notifications: {
    isOnTaskStartDate: boolean;
    isOn7DaysBeforeTaskDeadline: boolean;
    isOnTaskDeadline: boolean;
  };
  privacy: {
    isAllEmployeeCoachesCanSeeReview: boolean;
  };
  tasks: {
    isPreviouslyAutoGenerateEnabled: boolean;
    reviewSelfEvaluate: {
      startDate: Date | null;
      endDate: Date | null;
    };
    reviewPeerEvaluate: {
      startDate: Date | null;
      endDate: Date | null;
    };
    reviewCoachEvaluate: {
      startDate: Date | null;
      endDate: Date | null;
    };
    reviewPeerNominate: {
      startDate: Date | null;
      endDate: Date | null;
      description: ILanguageValue[] | null;
    };
  };
  fetchedCycle: IReview;
  userReviews?: IUserReview['id'][];
  isDirtyEmployees?: boolean;
}

export enum SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
}

export type GeneralSettingsType =
  | 'isCoachesAskedToScheduleReview'
  | 'isCalibrate'
  | 'isDigitalSign'
  | 'isAutoArchive'
  | 'isShowOverallRating';

export type NotificationSettingsType =
  | 'isOnTaskStartDate'
  | 'isOn7DaysBeforeTaskDeadline'
  | 'isOnTaskDeadline';

export type TasksType =
  | 'reviewCoachEvaluate'
  | 'reviewPeerEvaluate'
  | 'reviewSelfEvaluate'
  | 'reviewPeerNominate';
