import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { Icon, ICONS } from '~/components/Icon';

import {
  Section,
  CareerPlanCardWrapper,
  Label,
  PlaceholderWrapper,
  PlaceholderText,
  PlaceholderSection,
} from './design';

import noResult from '~/assets/no-result.webp';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import convertToTimeString from '~/utils/convertToTimeString';

import { CareerPlansSection, type ICareerPlansSectionDashboard } from '../types';

export const CareerSection = ({
  sections,
  onCareerPlanClick,
}: {
  sections: ICareerPlansSectionDashboard[];
  onCareerPlanClick: (jobProfileId: string) => void;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const [careerPlansSections, setCareerPlansSections] = useState<ICareerPlansSectionDashboard[]>(
    sections.map((item) => ({ ...item, isOpen: false })),
  );

  useEffect(() => {
    setCareerPlansSections((prevSections) =>
      sections.map((item) => {
        // keep the current state of section after updating the goals
        const isOpen = prevSections.find((section) => section.key === item.key)?.isOpen || false;
        return { ...item, isOpen };
      }),
    );
  }, [sections]);

  const visibleSections = careerPlansSections.filter(({ items }) => items?.length);

  return (
    <>
      {isEmpty(visibleSections) ? (
        <PlaceholderWrapper>
          <img src={noResult} alt={i18n._(t`No career chosen.`)} width="216px" />
          <PlaceholderText>{i18n._(t`No career chosen.`)}</PlaceholderText>
        </PlaceholderWrapper>
      ) : (
        careerPlansSections.map(
          ({ key, title, color, icon, items, isCollapsible, isOpen, placeholder }) => {
            const isClickable = isCollapsible && !isEmpty(items);
            return (
              <Section key={key}>
                <Label
                  color={color}
                  isOpen={isOpen}
                  isClickable={isClickable}
                  onClick={() => {
                    if (isClickable) {
                      setCareerPlansSections((prevState) =>
                        prevState.map((item) => ({
                          ...item,
                          isOpen: item.key === key ? !item.isOpen : item.isOpen,
                        })),
                      );
                    }
                  }}
                >
                  <div className="left">
                    <Icon icon={icon} />
                    <span>{title}</span>
                  </div>
                  {isClickable && (
                    <div className="right">
                      <Icon icon={ICONS.BACK} />
                    </div>
                  )}
                </Label>
                {isEmpty(items) && <PlaceholderSection>{placeholder}</PlaceholderSection>}
                {isCollapsible && !isOpen ? (
                  <></>
                ) : (
                  <>
                    {items?.map((careerPlan) => {
                      return (
                        <CareerPlanCardWrapper key={careerPlan.id}>
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                          <div
                            className="name"
                            onClick={() => onCareerPlanClick(careerPlan.jobProfile.id)}
                          >
                            {getMultiLangString(careerPlan.jobProfile.name)}
                          </div>
                          {[CareerPlansSection.ACTIVE, CareerPlansSection.PREVIOUS].includes(key) &&
                            !!careerPlan.startDate && (
                              <>
                                <div className="timeFrame">
                                  {convertToTimeString(careerPlan.startDate)} -{' '}
                                  {convertToTimeString(careerPlan.endDate) || `${i18n._(t`Now`)}`}
                                </div>
                              </>
                            )}
                        </CareerPlanCardWrapper>
                      );
                    })}
                  </>
                )}
              </Section>
            );
          },
        )
      )}
    </>
  );
};
