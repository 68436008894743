import React, { useCallback, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_THEME_STATUS,
} from '@learned/constants';
import {
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { debounce } from 'lodash';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { SECTION_TYPE } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { Header } from './components/Header';
import { StepNavigation } from './components/StepNavBar';
import { IGeneralForm, IQuestionForm } from './types';
import { convertQuestionOptions, getEvaluators, getQuestionType } from './utils';
import { resolver } from './validation';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import routes from '~/constants/routes';
import { useAutoSaveState } from '~/hooks/useAutoSaveState';
import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { deleteReviewThemes, getReviewTheme, updateReviewTheme } from '~/services/reviewThemes';
import { getSkillCategories } from '~/services/skillCategories';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { PopulatedReviewTheme } from '../ReviewThemeView/types';

const ReviewThemeSetup = () => {
  const { i18n } = useLingui();
  const { reviewThemeId } = useParams<{ reviewThemeId: string }>();
  const { addToast } = useToasts();
  const languageState = useLanguageState();
  const [skillCategories, setSkillCategories] = useState<
    { value: string; label: Record<string, string>; levels: number; type: string }[]
  >([]);
  const [differentRatingScales, setDifferentRatingScales] = useState<string[]>([]);

  const [oldStatus, setOldStatus] = useState<REVIEW_THEME_STATUS>(REVIEW_THEME_STATUS.DRAFT);

  const $isDraft = useBoolState();
  const $isLoading = useBoolState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const generalFormMethods: UseFormReturn<IGeneralForm> = useForm<
    IGeneralForm,
    { companyPrimaryLanguage: string }
  >({
    mode: 'all',
    resolver,
    context: { companyPrimaryLanguage: languageState.companyPrimaryLanguage.locale },
    defaultValues: {
      iconColor: '#f7f9ff-#ebf1fe',
      questions: [],
      status: REVIEW_THEME_STATUS.DRAFT,
    },
  });

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    trigger,
    handleSubmit,
  } = generalFormMethods;

  const statusWatch = watch('status');

  const questionsFormMethods = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  const sectionState = useSectionState([
    {
      title: i18n._(t`General`),
      fields: [i18n._(t`Theme Name`), i18n._(t`Icon & color`), i18n._(t`Description`)],
    },
    {
      title: i18n._(t`Questions`),
    },
  ]);

  const currentCompany = useSelector(getCurrentCompany);

  useEffect(() => {
    if (statusWatch === REVIEW_THEME_STATUS.DRAFT) {
      $isDraft.on();
    } else {
      $isDraft.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusWatch]);

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(
        Object.values(response).map((skillCatgory) => {
          const category = skillCatgory as {
            id: string;
            name: Record<string, string>;
            skillLevels: Record<string, string>[];
            type: string;
          };
          return {
            value: category.id,
            label: category.name,
            levels: category.skillLevels?.length,
            type: category.type,
          };
        }),
      );
    };

    fetchSkillCategories();
  }, [currentCompany]);

  useEffect(() => {
    $isLoading.on();
    const safeFetchTheme = async () => {
      const response = await getReviewTheme(reviewThemeId, ['questions'], ['templates']);

      const reviewTheme = response.data[API_RETURN_FIELDS.REVIEW_THEME];

      if (reviewTheme) {
        await handleFetchedTheme(reviewTheme);
      }
    };

    safeFetchTheme();
    // eslint-disable-next-line
  }, [reviewThemeId]);

  const handleFetchedTheme = async (reviewTheme: PopulatedReviewTheme) => {
    const themeQuestions: IQuestionForm[] = [];
    reviewTheme.questions?.forEach((question) => {
      const questionType = getQuestionType(question);

      if (!questionType) {
        return;
      }

      const themeQuestion = {
        id: question.id,
        name: turnMultiLangIntoArray(question.name, languageState.companyLanguages),
        description: turnMultiLangIntoArray(
          question.description || {},
          languageState.companyLanguages,
        ),
        templates: question.templates,
        type: { value: i18n._(`${questionType?.title}`), key: questionType.id },
        settings: {
          evaluators: getEvaluators((question.settings as IReviewQuestionDefaultData)?.evaluators),
          isCommentsAllowed:
            (question.settings as IReviewQuestionDefaultData)?.isCommentsAllowed || false,
          isCommentsObligated:
            (question.settings as IReviewQuestionDefaultData)?.isCommentsObligated || false,
          isAnswerObligated:
            !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
          isMeasurementReversed:
            (question.settings as IReviewQuestionDefaultData)?.isMeasurementReversed || false,
          isManualScale: (question.settings as IReviewQuestionDefaultData)?.isManualScale || false,
          skillCategory:
            (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
          skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
          subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
        },
        options: convertQuestionOptions(question, languageState.companyLanguages),
      };

      themeQuestions.push(themeQuestion);
    });

    const name = turnMultiLangIntoArray(reviewTheme.name, languageState.companyLanguages);
    const description = turnMultiLangIntoArray(
      reviewTheme.description,
      languageState.companyLanguages,
    );

    generalFormMethods.setValue('name', name);
    generalFormMethods.setValue('description', description);
    generalFormMethods.setValue('icon', (reviewTheme.icon as ICONS) || null);
    generalFormMethods.setValue('iconColor', reviewTheme.iconColor || '#f7f9ff-#ebf1fe');
    generalFormMethods.setValue('status', reviewTheme.status);
    generalFormMethods.setValue('questions', themeQuestions);

    setOldStatus(reviewTheme.status);

    await trigger();
    $isLoading.off();
  };

  const {
    lastSavedTime,
    lastSavedStatus,
    lastSavedErrorMessage,
    setLastSaveSuccess,
    setLastSaveError,
    setLastSavedErrorMessage,
  } = useAutoSaveState({ errorMessage: i18n._(t`Please fill all obligated fields`) });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTheme = useCallback(
    debounce(() => onAutosave(), 5000),
    [],
  );

  const handleUpdateTheme = async (status?: REVIEW_THEME_STATUS) => {
    if (!navigator.onLine) {
      setLastSaveError();
      setLastSavedErrorMessage(i18n._(t`Attempting to reconnect. Please check your connection.`));
      return;
    }

    const questionsIds = (watch('questions') || [])?.map((q) => q.id).filter((q) => q);
    const body = {
      name: turnArrayIntoMultiLang(watch('name').filter((langValue) => langValue.value !== '')),
      description: turnArrayIntoMultiLang(
        watch('description').filter((langValue) => langValue.value !== ''),
      ),
      icon: watch('icon'),
      iconColor: watch('iconColor'),
      questions: questionsIds,
      status: status || statusWatch,
    };

    const {
      data: { reviewTheme },
    } = await updateReviewTheme(reviewThemeId, body);

    debounceUpdateTheme.cancel();
    setLastSaveSuccess();

    return reviewTheme;
  };

  const nameInPrimaryLanguage = (watch('name') || [])?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  )?.value;

  useEffect(() => {
    if (!!errors?.name || !!errors?.icon) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setTypeSection(0, SECTION_TYPE.DONE);
    }
    if (differentRatingScales.length !== 0) {
      sectionState.setErrorSection(1, true);
    } else {
      sectionState.setErrorSection(1, false);
    }
    // eslint-disable-next-line
  }, [errors?.name, errors?.icon, differentRatingScales]);

  useEffect(() => {
    sectionState.setErrorSection(1, !!errors?.questions);
    // eslint-disable-next-line
  }, [errors?.questions]);

  const onFail = (isAutosave = false) => {
    if (!isAutosave) {
      sectionState.setTriedToSubmit();
      addToast({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Please fill all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
      setTimeout(() => sectionState.goToFirstErrorSection(), 100);
    } else {
      setLastSaveError();
    }

    setValue('status', oldStatus);
    trigger();
  };

  const onSubmit = async (
    status: REVIEW_THEME_STATUS,
    options?: { isAutoSave?: boolean; onSave?: boolean },
  ) => {
    const reviewResponse = await handleUpdateTheme(
      !options?.isAutoSave ? status : generalFormMethods.getValues().status,
    );

    if (options?.isAutoSave) {
      return;
    }

    if (status === REVIEW_THEME_STATUS.PUBLISHED && !options?.onSave) {
      addToast({
        title: i18n._(t`Theme published`),
        type: TOAST_TYPES.SUCCESS,
      });
    } else if (reviewResponse.status === REVIEW_THEME_STATUS.DRAFT) {
      addToast({
        title: i18n._(t`Theme saved as draft`),
        type: TOAST_TYPES.INFO,
      });
    } else {
      addToast({
        title: i18n._(t`Theme saved`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
    routes.REVIEW_THEME_VIEW.go({}, { reviewThemeId: reviewResponse.id, isBackPath: true });
  };

  const validateRatingScales = async () => {
    const companyRatingLength = 5;

    const questions = generalFormMethods.getValues().questions;

    const hasCustomRatingScale = questions.some((question) => {
      if ('isManualScale' in question.settings && question.settings.isManualScale) {
        return true;
      }
      return false;
    });

    const ratingLengths: { [key: number]: number } = {};
    if (hasCustomRatingScale) {
      questions.forEach((question) => {
        // Skip questions of type GOAL_PLAN or TEXT
        if (
          question.type.key === QUESTION_TYPES.GOAL_PLAN ||
          question.type.key === QUESTION_TYPES.TEXT
        ) {
          return;
        }

        let questionRatingLength;
        question.options
          ? (questionRatingLength = question.options.length)
          : (questionRatingLength = companyRatingLength);

        ratingLengths[questionRatingLength] = (ratingLengths[questionRatingLength] || 0) + 1;
      });

      // Find the most common rating length
      let mostCommonLength: number;
      let maxCount = 0;

      for (const [length, count] of Object.entries(ratingLengths)) {
        if (count > maxCount) {
          mostCommonLength = parseInt(length, 10);
          maxCount = count;
        }
      }

      // Filter questions that don't match the most common length
      const questionsWithMismatchedScales = questions.filter((question) => {
        // Skip questions of type GOAL_PLAN or TEXT
        if (
          question.type.key === QUESTION_TYPES.GOAL_PLAN ||
          question.type.key === QUESTION_TYPES.TEXT
        ) {
          return false;
        }

        const questionRatingLength = question.options
          ? question.options.length
          : companyRatingLength;
        return questionRatingLength !== mostCommonLength;
      });

      if (questionsWithMismatchedScales.length > 0) {
        setDifferentRatingScales(
          questionsWithMismatchedScales
            .map((q) => q.id)
            .filter((id): id is string => id !== undefined),
        );
      }

      if (questionsWithMismatchedScales.length > 0) {
        return false;
      }
    }

    return true;
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      () => {
        return onSubmit(REVIEW_THEME_STATUS.DRAFT);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onDelete = async () => {
    const result = await deleteReviewThemes([reviewThemeId]);
    if (result.code === 200) {
      routes.REVIEWS.go({}, { hash: 'themes' });
      addToast({
        title: i18n._(t`Theme deleted`),
        subtitle: i18n._(t`Theme is deleted from review themes`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
  };

  const onPublish = async (onSave?: boolean) => {
    const submit = handleSubmit(
      async () => {
        if (await validateRatingScales()) {
          setValue('status', REVIEW_THEME_STATUS.PUBLISHED);
          return onSubmit(REVIEW_THEME_STATUS.PUBLISHED, { onSave });
        }
      },
      () => onFail(),
    );

    return submit();
  };

  const onAutosave = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      () => {
        return onSubmit(
          $isDraft.value ? REVIEW_THEME_STATUS.DRAFT : REVIEW_THEME_STATUS.PUBLISHED,
          { isAutoSave: true },
        );
      },
      () => onFail(true),
    );
    return submit(e);
  };

  return (
    <>
      <Header
        title={`${
          $isDraft.value ? `${i18n._(t`Create theme`)}: ` : `${i18n._(t`Edit theme`)}: `
        } ${nameInPrimaryLanguage}`}
        lastSavedTime={lastSavedTime}
        lastSavedStatus={lastSavedStatus}
        lastSavedErrorMessage={lastSavedErrorMessage}
        isLoading={$isLoading.value}
        isDraft={$isDraft.value}
        languageState={languageState}
        onSave={onSave}
        onPublish={onPublish}
        onDelete={() => setShowDeleteModal(true)}
      />
      <StepNavigation
        setLastSaveSuccess={setLastSaveSuccess}
        formMethods={generalFormMethods}
        languageState={languageState}
        sectionState={sectionState}
        onSave={onSave}
        onPublish={onPublish}
        onAutosave={onAutosave}
        questionsFormMethods={questionsFormMethods}
        reviewThemeId={reviewThemeId}
        debounceUpdateTheme={debounceUpdateTheme}
        skillCategories={skillCategories}
        differentRatingScales={differentRatingScales}
      />
      {showDeleteModal && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete theme?`)}
          description={i18n._(
            t`Deleting this theme will delete it from the review report. You can no longer view the data for this theme and the related question(s). This action cannot be undone.`,
          )}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={() => onDelete()}
        />
      )}
    </>
  );
};

export { ReviewThemeSetup };
