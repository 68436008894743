import {
  FocusAreaType,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
} from '@learned/constants';
import { isPast, isToday } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';

import type { TasksType } from '~/pages/Reviews/EditCycle/ReviewCycleForm/types';

import type {
  IJobProfile,
  IMultiLangString,
  ISkill,
  IReview,
  IReviewQuestion,
  IReviewTheme,
  ISkillCategory,
  IReviewQuestionDefaultData,
  IReviewTemplate,
} from '@learned/types';

export const transformToISOString = (date: Date | string | null) => {
  if (date === null) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const currentDate = moment(date);

  // find the offset between local time and UTC time
  const offsetHours = Math.abs(currentDate.utcOffset()) / 60;

  return (
    currentDate
      .startOf('day')
      // set hours to 12 to cover the whole countries in the world for that day
      .set({ hours: 12 + offsetHours })
      .toISOString()
  );
};

export const getSkillFocusAreas = (
  skill: ISkill,
  filters?: { levels?: number[] | null; ids?: string[] | null },
) => {
  return _.chain(skill.focusAreas)
    .filter((item) => !filters?.levels || filters?.levels.includes(item.level)) // filter by levels, only if levels exist
    .map((level) => Object.values(level.values))
    .flatten()
    .filter((focusArea) => !filters?.ids || filters?.ids.includes(focusArea.id)) // filter by ids, only if ids exist
    .uniq()
    .value();
};

export const skillWithFocusAreaCheck = ({
  job,
  skills,
}: {
  job: IJobProfile;
  skills: ISkill[];
}) => {
  const skillsWithFocusArea: ISkill[] = [];
  const skillsWithoutFocusArea: ISkill[] = [];

  job.skills.forEach(({ skill, selectedFocusAreas }) => {
    const skillData = skills.find((item: ISkill) => item.id === skill);
    if (skillData) {
      const focusAreas: Array<{ id: string; name: IMultiLangString }>[] = [];
      selectedFocusAreas.forEach((item) => {
        switch (item.type) {
          case FocusAreaType.SELECT_ALL: {
            focusAreas.push(getSkillFocusAreas(skillData));
            break;
          }
          case FocusAreaType.SELECT_LEVEL: {
            focusAreas.push(
              getSkillFocusAreas(skillData, {
                levels: [item.level as unknown as number],
              }),
            );
            break;
          }
          case FocusAreaType.SELECT_FOCUS_AREA: {
            focusAreas.push(
              getSkillFocusAreas(skillData, {
                ids: [item.focusArea as unknown as string],
              }),
            );
            break;
          }
          default:
            break;
        }
      });

      if (_.isEmpty(focusAreas.filter((item) => !_.isEmpty(item)))) {
        skillsWithoutFocusArea.push(skillData);
      } else {
        skillsWithFocusArea.push(skillData);
      }
    }
  });

  return { skillsWithFocusArea, skillsWithoutFocusArea };
};

// startDate NOT possible to edit when
// review PUBLISHED or ACTIVE
// AND startDate today or in the past
export const isStartDateDisabled = (review: IReview, key: TasksType) => {
  const startDateString = review.tasks ? review.tasks[key]?.startDate : null;
  const startDate = startDateString ? new Date(startDateString) : null;

  const isPublished = review.status === REVIEW_STATUS.PUBLISHED;
  const isActive = review.status === REVIEW_STATUS.ACTIVE;

  return Boolean(
    (isPublished || isActive) && startDate && (isToday(startDate) || isPast(startDate)),
  );
};

// startDate NOT possible to edit when
// review PUBLISHED or ACTIVE
// AND startDate today or in the past
export const isReviewStartDateDisabled = (review: IReview) => {
  const startDateString = review.settings.startDate;
  const startDate = startDateString ? new Date(startDateString) : null;

  const isPublished = review.status === REVIEW_STATUS.PUBLISHED;
  const isActive = review.status === REVIEW_STATUS.ACTIVE;

  return Boolean(
    (isPublished || isActive) && startDate && (isToday(startDate) || isPast(startDate)),
  );
};

export const getRelevantSkillCategoriesToValidate = ({
  questions = [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  themeWeights,
}: {
  questions: IReviewQuestion[];
  themeWeights: Record<string, number>;
}) => {
  const questionsPerTheme: Record<string, IReviewQuestion[]> = {};

  questions.forEach((question) => {
    const { theme, type, settings } = question;

    // If the theme has no weight, do not include the question
    if (!themeWeights[theme]) {
      return;
    }

    // If the quesiton is not obligated, do not include it
    // @ts-ignore
    if (settings.isAnswerObligated === false) {
      return;
    }

    // If the question is not part of a rated type, do not include it
    if (
      ![
        REVIEW_QUESTION_TYPES.RATING,
        REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
        REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
      ].includes(type as REVIEW_QUESTION_TYPES)
    ) {
      return;
    }

    // If the question has a manual scale, do not include it
    // @ts-ignore
    if (settings.isManualScale) {
      return;
    }

    if (!questionsPerTheme[theme]) {
      questionsPerTheme[theme] = [];
    }
    questionsPerTheme[theme].push(question);
  });

  // Go through every relevant theme questions and define for which ones it is relevant to check
  // if we need to validate skill categories having skills
  // !important we define skill-categories per theme
  const relevantSkillCategoriesPerTheme: Record<IReviewTheme['id'], string[]> = {};
  Object.keys(questionsPerTheme).forEach((themeId) => {
    const themeQuestions = questionsPerTheme[themeId] as unknown as IReviewQuestion[];

    if (!relevantSkillCategoriesPerTheme[themeId]) {
      relevantSkillCategoriesPerTheme[themeId] = [];
    }

    // By this point, all the questions are rated and relevant, so if any has a type that is not skill categories
    // assume that we do not need to validate any of the rest for skill categories
    if (themeQuestions.some(({ type }) => type !== REVIEW_QUESTION_TYPES.SKILL_CATEGORY)) {
      return;
    }

    // If we reach this point we can assume that all the rated relevant questions in the theme are for skill category
    // So we can accumulate all their skill category ids
    themeQuestions.forEach(({ settings }: { settings: any }) => {
      if (settings.skillCategory) {
        relevantSkillCategoriesPerTheme[themeId].push(settings.skillCategory);
      }
    });
  });

  return relevantSkillCategoriesPerTheme;
};

interface IValidateTheme {
  skillCategoriesIds: ISkillCategory['id'][];
  jobs: IJobProfile[];
  skills: ISkill[];
}

export const validateTheme = ({ skillCategoriesIds, jobs, skills }: IValidateTheme) => {
  const skillCategoriesToValidate = skillCategoriesIds;

  let isNoSkillError = true; // stay true if all skill in skill-categories for Theme -> no skill
  let isNoFocusAreaError = true; // stay true if all skills in skill-categories from Themes -> do no have any focus-area
  const skillsWithoutFocusAreaAll: Array<{ id: string; name: IMultiLangString }> = [];
  const jobsWithoutSkillsAll: Array<{ id: string; name: IMultiLangString }> = [];

  // Go through jobs, and for each skill category that is relevant to check, show error message
  // if it does not have skills
  jobs.forEach((job) => {
    const jobSkillIds = job.skills.map((item) => item.skill);
    const jobSkillsToValidate: ISkill[] = [];

    jobSkillIds.forEach((skillId) => {
      const skill = (skills as ISkill[]).find((skill) => skill.id === skillId);

      if (skill && skillCategoriesToValidate.includes(skill.skillCategory)) {
        jobSkillsToValidate.push(skill);
      }
    });

    // if min 1 relevant skill-category in theme match with criteria
    // - min 1 skill
    // then validation passed
    if (!_.isEmpty(jobSkillsToValidate)) {
      isNoSkillError = false;
    } else {
      jobsWithoutSkillsAll.push(job);
    }

    // if min 1 relevant skill-category in theme match with criteria
    // - min 1 skill with min 1 focusArea
    // then validation passed
    const { skillsWithFocusArea, skillsWithoutFocusArea } = skillWithFocusAreaCheck({
      job,
      skills: jobSkillsToValidate,
    });

    // important to check skills WITH focusArea,
    // if min 1 exist -> then Theme passed validation
    if (!_.isEmpty(skillsWithFocusArea)) {
      isNoFocusAreaError = false;
    }

    // we store skills without FA to show in error (if all skills do not have fa)
    // if min 1 skill have fa -> we do nothing with it
    skillsWithoutFocusAreaAll.push(...skillsWithoutFocusArea);
  });

  return {
    isNoSkillError,
    isNoFocusAreaError,
    skillsWithoutFocusArea: skillsWithoutFocusAreaAll,
    jobsWithoutSkills: jobsWithoutSkillsAll,
  };
};

export const areWeightedThemesValid = (selectedTemplate: IReviewTemplate | undefined) => {
  const themeIds = selectedTemplate?.themeWeights
    ? Object.entries(selectedTemplate.themeWeights)
        .map(([key, weight]) => {
          if (key && weight > 0) {
            return key;
          }
          return undefined;
        })
        .filter((key): key is string => Boolean(key))
    : [];

  const questionList: IReviewQuestion[] =
    selectedTemplate && Array.isArray(selectedTemplate?.questions)
      ? (selectedTemplate.questions as unknown as IReviewQuestion[])
      : [];

  const isRequiredRatedQuestion = (question: IReviewQuestion) => {
    return (
      question.type === REVIEW_QUESTION_TYPES.RATING &&
      (question.settings as IReviewQuestionDefaultData).isAnswerObligated &&
      (question.settings as IReviewQuestionDefaultData)?.isManualScale === false &&
      (question.settings as IReviewQuestionDefaultData)?.evaluators.includes(
        REVIEW_QUESTION_EVALUATORS.COACH,
      )
    );
  };

  const getWeightedThemes = (themeIds: string[], questionsList?: IReviewQuestion[]) => {
    return themeIds.filter(() => {
      if (!questionsList) {
        return false;
      }
      const weightedThemeQuestions = Object.values(questionsList).filter((question) =>
        isRequiredRatedQuestion(question),
      );
      return weightedThemeQuestions.length > 0;
    });
  };

  return (
    getWeightedThemes(themeIds, questionList).length === themeIds.length &&
    selectedTemplate?.isCustomWeight !== false &&
    selectedTemplate
  );
};
